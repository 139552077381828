<template>
  <div>
    <CCard>
            <CCardHeader><font-awesome-icon icon="address-book"/> Signatories
            </CCardHeader>
            
            <CCardBody>
                <CDataTable
                    :items="signatories"
                    :fields="signatories_field"
                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                    :items-per-page="10"
                    border
                    items-per-page-select
                    sorter
                    pagination
                    :loading="this.table_loading"
                >
                <template slot="no-items-view" v-if="this.table_loading">
                    <center>
                        <CSpinner
                            style="width:4rem;height:4rem;"
                            color="info"
                        />
                    </center>
                </template>
                <template #name="{item}">
                    <td>
                        {{ item.name }}
                    </td>
                </template>
                <template #summary="{item}">
                    <td>
                        <CButton
                            pressed
                            aria-pressed="true"
                            variant="ghost"
                            class="btn-summary"
                            title="Number of document(s) signed."
                            color="secondary" 
                        >
                        Signed {{ item.document_signed_count }} document/s 
                        </CButton>
                        &nbsp;
                        <CButton  @click="$refs.dtSignatoryModal.dt_signatory_modal = true, emitDtSignatoryModal(item.id, item.name)"
                            pressed
                            aria-pressed="true"
                            variant="ghost"
                            class="btn-summary"
                            title="Number of document template(s) this user belong."
                            color="dark" 
                        >
                            Belongs to {{ item.document_template_count }} document template/s
                        </CButton>
                         
                    </td>
                </template>
                
                <template #Action="{item}">
                    <td>
                        <router-link :to="{ path: `/${$store.getters['getOrganizationName']}/drs/document-template-signatory/${paramEncoder(item.id)}`, query: {name: `${item.name}`, signed: paramEncoder(item.document_signed_count), template: paramEncoder(item.document_template_count)} }">
                            <CButton 

                                color="info" 
                                shape="pill" 
                            >
                                <font-awesome-icon icon="cog"/> Manage
                            </CButton>
                        </router-link>
                    </td>
                </template>
                </CDataTable>
            </CCardBody>
        </CCard>
        <DocumentTemplateSignatoryModal ref="dtSignatoryModal" />
  </div>
</template>

<script>
import DocumentTemplateSignatoryModal from '../../modals/DTSignatoryModal'
export default {
    name: 'DocumentTemplateSignatoryLists',
    components : {
        DocumentTemplateSignatoryModal
    },
    data() {
        return {
            table_loading: true,
            signatories:[],
        }
    },
    computed: {
        
        signatories_field: function () {
            let custom_fields= [
                { key: 'name', label: 'Name'},
                { key: 'summary'},
                // { key: 'status' },
            ]
            if(this.$store.getters.can('view-document-template-signatory')) {
                custom_fields.push({ key: 'Action', sorter: false})
            }
            return custom_fields;
        }
    },
    created() {
        this.getSignatories();
        this.$emit('activeTab', 0);
    },
    methods: {
        getSignatories() {
            this.$Progress.start()
            axios.get('/drs/document-template-signatory/list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.signatories = response.data.data;
                    this.table_loading = false;
                    this.$Progress.finish()
                }
            })
        },

        emitDtSignatoryModal: function (user_id, name) {
            this.$emit('show_dt_signatory_modal', user_id, name);
        }, 
    }

}
</script>

<style>
/* .btn-summary {
    cursor: pointer;
} */
</style>